import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="./Miller_Ball_22 2.0 Icon.png" className="App-logo" alt="logo" />
        <p>
          Coming Soon!!!
        </p>
      </header>
    </div>
  );
}

export default App;
